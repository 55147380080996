Astro.fancyGrids = [];

Astro.fancyGrid = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('.js-fancy-grid'), function(array, i) {
        var options = {};

        if (array[i].classList.contains('fancy-grid--inspiration')) {
            options.afterUpdate = inspirationAfterUpdate;
        }

        var fancyGrid = new FancyGrid(array[i], options);
        fancyGrid.init();

        Astro.fancyGrids.push(fancyGrid);
    });
}

// Make sure the title elements all have the same height
var inspirationAfterUpdate = function (fancyItems) {
    var loop = new Loop();
    var viewport = new Viewport(Astro.breakpoints);

    loop.array(fancyItems, function(items, i) {
        if (viewport.matches('tablet')) {
            var title = fancyItems[i].querySelector('.inspiration-listing__title');
            title.style.height = null;
        } else {
            if (i != 1 && (i + 1) % 6 == 0) {
                var compareIds = [i, i - 1, i - 2];
                var titleHeights = compareIds.map(function (index) {
                    var title = fancyItems[index].querySelector('.inspiration-listing__title');
                    title.style.height = null;
                    return title.clientHeight;
                });

                var maxHeight = Math.max.apply(null, titleHeights);

                compareIds.forEach(function (index) {
                    var title = fancyItems[index].querySelector('.inspiration-listing__title');
                    title.style.height = maxHeight + 'px';
                });
            }
        }
    });
};
