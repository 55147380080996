Astro._slidingAccordions = [];

Astro.slidingAccordions = function () {
    var loop = new Loop();

    var scrollTarget = document.querySelector('.where-buy__right');
    var offsetElements = [
        document.querySelector('#admin-bar'),
        document.querySelector('.debug-bar'),
        document.querySelector('.notice'),
        document.querySelector('.header'),
    ];

    loop.array(document.querySelectorAll('.js-sliding-accordion'), function (array, i) {
        var slidingAccordion = new SlidingAccordion({
            el: array[i],
            scrollTarget: scrollTarget,
            scrollOffsetElements: offsetElements,
        });
        slidingAccordion.init();

        Astro._slidingAccordions.push(slidingAccordion);
    });
};
