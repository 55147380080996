var multiSelect = {
    classes: {
        focused:  'multi-select--focused',
        disabled: 'multi-select--disabled',
        single:   'multi-select--single',
        multiple: 'multi-select--multiple',
        grouped:    'multi-select--grouped',
        search:   'multi-select--search',
        active:   'multi-select--active',
        listitem: 'multi-select__list-item',
        group:    'multi-select__list-item--group',
        selected: 'multi-select__list-item--selected',
        current:  'multi-select__list-item--current',
        nobbled:  'multi-select__list-item--disabled',
        hidden:   'multi-select__list-item--hidden',
        image:    'multi-select__list-item--image',
    },

    keys: {
        up:     38,
        dn:     40,
        pgup:   33,
        pgdn:   34,
        escape: 27,
        space:  32,
        enter:  13,
    },

    page: 5,

    $focused: false,
};

$.fn.multiSelect = function(options) {

    var defaults = {
        icon: 'arrow-small-down',
        iconFocus: 'arrow-small-up',
    };

    var options = $.extend(defaults, options);

    $(this).filter(':not(.multi-selected)').each(function() {
        var $select = $(this);

        var $form     = $select.closest('form');
        var $label    = $select.prev('label');
        var $wrapper  = $select.wrap('<div class="multi-select"></div>').parent();
        var $value     = $('<p class="multi-select__value"></p>').appendTo($wrapper);
        var $icon      = $('<span class="multi-select__icon multi-select__icon--unfocused"></span>').appendTo($wrapper);
        var $iconFocus = $('<span class="multi-select__icon multi-select__icon--focused"></span>').appendTo($wrapper);
        var $input     = $('<input class="multi-select__search">').appendTo($wrapper);
        var $drop      = $('<div class="multi-select__drop"></div>').appendTo($wrapper);
        var $up        = $('<a href="#" class="multi-select__elevator-button multi-select__elevator-button--up"></a>').appendTo($drop);
        var $down      = $('<a href="#" class="multi-select__elevator-button multi-select__elevator-button--down"></a>').appendTo($drop);

        var svg = new Svg();
        $icon.html(svg.render(options.icon));
        $iconFocus.html(svg.render(options.iconFocus));

        var $list = $('<ul class="multi-select__list"></ul>');
        $select.find('option').each(function(ndx) {
            var $option = $(this);

            var groupClass = '';
            var dataString = '';
            if ($option.data('filter-optgroup')) {
                groupClass = ' ' + multiSelect.classes.group;
                dataString = ' data-optgroup="' + $option.data('filter-optgroup') + '"';
            }

            if ($option.data('filter-optfor')) {
                dataString = ' data-optfor="' + $option.data('filter-optfor') + '"';
            }

            var $li = $('<li class="' + multiSelect.classes.listitem + groupClass + '" data-value="' + $option.val() + '"' + dataString + '><a href="#0">' + $option.text() + '</a></li>');

            if ($option.is('[data-hidden]')) {
                $li.addClass(multiSelect.classes.hidden);
            }

            if ($option.is(':disabled')) {
                $li.addClass(multiSelect.classes.nobbled);
            }

            if ($option.attr('data-image') !== undefined) {
                $('<img src="' + $option.attr('data-image') + '" />').prependTo($li);
                $li.addClass(multiSelect.classes.image);
            }

            $('<input type="checkbox" name="select-check" value="' + $option.val() + '" check-replace />').prependTo($li);

            $li.appendTo($list);
        });

        $list.appendTo($drop);
        var $options = $list.find('li');

        if ($select.prop('multiple')) {
            $wrapper.addClass(multiSelect.classes.multiple);
        } else {
            $wrapper.addClass(multiSelect.classes.single);

            if ($options.length > 100) {
                $wrapper.addClass(multiSelect.classes.search);
            }
        }

        if ($select.data('grouped')) {
            $wrapper.addClass(multiSelect.classes.grouped);
        }

        $up.on('click', function(e) {
            e.preventDefault();

            var scroll = $list.scrollTop() - parseInt($list.height() / 2);
            $list.stop(true, true).animate({scrollTop: scroll}, 'slow');
        });

        $down.on('click', function(e) {
            e.preventDefault();

            var scroll = $list.scrollTop() + parseInt($list.height() / 2);
            $list.stop(true, true).animate({scrollTop: scroll}, 'slow');
        });

        $up.add($down).each(function() {
            var $button = $(this);

            $button.on('mousedown', function() {
                $button.addClass('multi-select__elevator-button-click');
            });

            $button.on('mouseup', function() {
                $button.removeClass('multi-select__elevator-button-click');
            });
        });

        if ($select.prop('id')) {
            $select.parent().prop('id', $select.prop('id') + '_replace');
        }

        $select.addClass('multi-selected');

        if ($select.prop('disabled')) {
            $wrapper.addClass(multiSelect.classes.disabled);
        }

        $value.on('click', function(e) {
            if (multiSelect.$focused) {
                if (multiSelect.$focused !== $wrapper) {
                    // clicked a different select
                    multiSelect.$focused.trigger('_blur');

                    $wrapper.trigger('_focus');
                } else {
                    $wrapper.trigger('_blur');
                }
            } else {
                $wrapper.trigger('_focus');
            }
        });

        $label.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $value.trigger('click');
        });

        $list.on('click', '.' + multiSelect.classes.listitem, function(e, triggerEvent) {
            e.preventDefault();

            if (typeof triggerEvent === "undefined" || triggerEvent === null) {
                triggerEvent = true;
            }

            var $item = $(this);
            var $check = $item.find('input');

            $item.toggleClass(multiSelect.classes.selected);

            if (!$select.prop('multiple')) {
                var $siblings = $item.siblings().filter('.' + multiSelect.classes.selected);
                $siblings.removeClass(multiSelect.classes.selected);
                $siblings.each(function(ndx, sibling) {
                    $select.trigger('_unselected', sibling);
                });
                $wrapper.trigger('_blur');
            }

            if (triggerEvent) {
                var eventName = $item.hasClass(multiSelect.classes.selected) ? '_selected' : '_unselected';

                $select.trigger(eventName, this);
                $check.prop('checked', (eventName === '_selected'))
                $check.trigger('change');
            }

            $wrapper.trigger('_change');
        });

        $wrapper.on('_focus', function() {
            multiSelect.$focused = $wrapper;
            $wrapper.addClass(multiSelect.classes.focused);

            if (!$select.prop('multiple')) {
                $input.trigger('focus');
            }
        });

        $wrapper.on('_blur', function () {
            multiSelect.$focused = false;
            $wrapper.removeClass(multiSelect.classes.focused);
        });

        $wrapper.on('_change', function (e, initial) {
            var $selected = $options.filter('.' + multiSelect.classes.selected);

            var selectValue = globalTranslations.selectDefaultValue;
            var active = true;
            if ($select.prop('multiple')) {
                active = false;
            }

            if ($select.data('blank') !== undefined) {
                selectValue = $select.data('blank');
            }

            if ($select.data('default-first') !== undefined) {
                selectValue = $options.eq(0).text()
            }

            if ($selected.length) {
                if ($select.prop('multiple')) {
                    selectValue = $selected.length + ' selected';
                    active = true;
                } else {
                    var $option = $select.find('option').eq($selected.index());
                    var value = $option.prop('value');
                    if (value != '') {
                        selectValue = $selected.text();
                    }
                    if (value == -1 || value == '') {
                        active = false;
                    }
                }
            }

            $value.html(selectValue);

            if (active) {
                $wrapper.addClass(multiSelect.classes.active);
            } else {
                $wrapper.removeClass(multiSelect.classes.active);
            }

            $select.find('option').each(function(ndx) {
                var $option = $(this);

                if ($options.eq(ndx).hasClass(multiSelect.classes.selected)) {
                    $option.prop('selected', true);
                } else {
                    $option.prop('selected', null);
                }
            });

            if (!initial) {
                $select.trigger('change', initial);
            }
        });

        $list.on('_filter', function(e, search) {
            $options.removeClass(multiSelect.classes.current);

            search = search.toUpperCase();

            $options.each(function() {
                var $option = $(this);
                var label = $option.text().toUpperCase();
                if ((search == '') || (label.indexOf(search) > -1)) {
                    $option.show();
                } else {
                    $option.hide();
                }
            });
        });

        $list.on('_navigate', function(e, offset) {
            var $visible = $options.filter(':visible');
            var count = $visible.length;

            var $current = $options.filter('.' + multiSelect.classes.current);
            var focus = ($current.length > 0) ? $visible.index($current) : -1;

            focus += offset;
            if (focus < 0) {
                focus = 0;
            }
            if (focus > count-1) {
                focus = count-1;
            }

            if (focus > -1) {
                var $li = $visible.eq(focus);
                $li.addClass(multiSelect.classes.current).siblings().removeClass(multiSelect.classes.current);

                var lt = $list.scrollTop();
                var lb = lt + $list.height();
                var it = lt + $li.position().top;
                var ib = it + $li.height();

                var up = it - lt - 5;
                var dn = ib - lb;

                if (up < 0) {
                    $list.scrollTop(lt + up);
                }

                if (dn > 0) {
                    $list.scrollTop(lt + dn);
                }
            }
        });

        $(document).on('keydown', function(e) {
            if (!multiSelect.$focused) {
                return;
            }

            var systemKey = false;
            for (var k in multiSelect.keys) {
                if (e.which == multiSelect.keys[k]) {
                    systemKey = e.which;
                }
            }

            var search = $wrapper.hasClass(multiSelect.classes.search);

            if (search && e.which == multiSelect.keys.escape) {
                systemKey = false;
            }

            if (systemKey) {
                e.preventDefault();
            }

            if (e.which == multiSelect.keys.dn) {
                $list.trigger('_navigate', 1);
            }

            if (e.which == multiSelect.keys.up) {
                $list.trigger('_navigate', -1);
            }

            if (e.which == multiSelect.keys.pgup) {
                $list.trigger('_navigate', multiSelect.page * -1);
            }

            if (e.which == multiSelect.keys.pgdn) {
                $list.trigger('_navigate', multiSelect.page * 1);
            }

            if (e.which == multiSelect.keys.space) {
                if (!search) {
                    var $current = $options.filter('.' + multiSelect.classes.current);

                    $current.toggleClass(multiSelect.classes.selected);

                    if (!$select.prop('multiple')) {
                        $current.siblings().removeClass(multiSelect.classes.selected);
                    }

                    $wrapper.trigger('_change');
                }
            }

            if (e.which == multiSelect.keys.escape) {
                $wrapper.trigger('_blur');
            }

            if (e.which == multiSelect.keys.enter) {
                var $current = $options.filter('.' + multiSelect.classes.current);
                if ($current.length > 0) {
                    $current.trigger('click');
                }
            }
        });

        $input.on('focus', function(e) {
            $input.closest('.adaptive-placeholder').addClass('adaptive-placeholder--active');
        });

        $input.on('blur', function(e) {
            if ($select.prop('value') == '') {
                $input.closest('.adaptive-placeholder').removeClass('adaptive-placeholder--active');
            }
        });

        $input.on('keyup', function(e) {
            if (!multiSelect.$focused) {
                return;
            }

            var systemKey = false;
            for (var k in multiSelect.keys) {
                if (e.which == multiSelect.keys[k]) {
                    systemKey = e.which;
                }
            }

            var search = $wrapper.hasClass(multiSelect.classes.search);

            if (search && e.which == multiSelect.keys.escape) {
                systemKey = false;
            }

            if (!systemKey) {
                $list.trigger('_filter', $input.val());
            }
        });

        $select.on('_change', function() {
            $select.find('option').each(function(ndx) {
                var $option = $(this);

                if ($option.prop('selected')) {
                    $li = $options.eq(ndx);
                    $li.addClass(multiSelect.classes.selected);

                    $check = $li.find('input')
                    $check.prop('checked', true)
                    $check.trigger('change');
                }
            });
        });

        $select.find('option').on('_update', function(e, label) {
            var $option = $(this);
            var $item = $options.eq($option.index());
            $item.find('a').html(label);

            var hide = (label.indexOf('(0)') > -1);
            $item.toggleClass(multiSelect.classes.hidden, hide);
        });

        $form.on('reset', function(e) {
            $options.removeClass(multiSelect.classes.selected);
            $wrapper.trigger('_change');
        });

        $select.trigger('_change', true);
    });
};

$(document).on('click', function(e) {
    if (multiSelect.$focused) {
        if (!$.contains(multiSelect.$focused.get(0), e.target)) {
            // clicked outside of the current select
            multiSelect.$focused.trigger('_blur');
        }
    }
});
