function VideoBlock(el) {
    this.init = function() {
        var iframe = el.querySelector('iframe');
        var videoElement = el.querySelector('video');
        var previewElement = el.querySelector('.block__video-preview');
        var controlElement = el.querySelector('.block__pause');
        if (iframe) {
            var vimeoPlayer = new Vimeo.Player(iframe);
            iframe.addEventListener('load', function () {
                vimeoPlayer.getPaused().then(function (paused) {
                    if (!paused) {
                        previewElement.classList.add('block__thumbnail-toggle');
                    }
                });
            });

            el.querySelector('.block__video-preview').addEventListener('click', function () {
                vimeoPlayer.play();
                previewElement.classList.toggle('block__thumbnail-toggle');
            });

            controlElement.addEventListener('click', function (event) {
                event.preventDefault();

                vimeoPlayer.getPaused().then(function (paused) {
                    paused ? vimeoPlayer.play() : vimeoPlayer.pause();
                })
            });

            vimeoPlayer.on('pause', function () {
                controlElement.classList.add('block__pause--paused');
            });

            vimeoPlayer.on('play', function () {
                controlElement.classList.remove('block__pause--paused');
            });
        } else if (videoElement) {
            videoElement.addEventListener('canplay', function () {
                if (!videoElement.paused || videoElement.autoplay) {
                    previewElement.classList.add('block__thumbnail-toggle');
                }
            });

            el.querySelector('.block__video-preview').addEventListener('click', function () {
                videoElement.play();
                previewElement.classList.toggle('block__thumbnail-toggle');
            });

            controlElement.addEventListener('click', function (event) {
                event.preventDefault();
                videoElement.paused ? videoElement.play() : videoElement.pause();
            });

            videoElement.addEventListener('pause', function () {
                controlElement.classList.add('block__pause--paused');
            });

            videoElement.addEventListener('play', function () {
                controlElement.classList.remove('block__pause--paused');
            });
        }
    }
}
