Astro.moves = [];

Astro.move = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('[move-parent]'), function(array, i) {
        var move = new Move({
            el: array[i]
        });
        move.init();

        Astro.moves.push(move);
    });
};
