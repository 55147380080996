function Breakpoint() {

    // Get the breakpoint config
    this.config = Astro.breakpoints;

    // Constuct the breakpoint for the media query
    this.construct = function(breakpoint) {
        return '(' + this.config[breakpoint].type + ': ' + this.config[breakpoint].size + 'px)';
    }

    // Check if the media query matches
    this.matchMedia = function(breakpoint) {
        return window.matchMedia(this.construct(breakpoint));
    }

    // Check if the media query matches
    this.matches = function(breakpoint) {
        return window.matchMedia(this.construct(breakpoint)).matches;
    }
}
