Astro.quantityControls = [];

Astro.quantityControl = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('.js-quantity-control'), function(array, i) {
        var quantityControl = new Astro.QuantityControl({
            el: array[i],
            mode: array[i].getAttribute('quantity-control'),
            id: 'quantity-control-' + i
        });
        quantityControl.init();

        Astro.quantityControls.push(quantityControl);
    });
};
