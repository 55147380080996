Astro.hotspotGallery = function() {
    $('[data-hotspot-gallery]')
        .each(function(i, v) {
            var gallery = $(this),
                crosshairs = $(this).find('.gallery__crosshair'),
                activeClass = 'gallery__crosshair--active';

            gallery
                .on('hotspot.hide', function(e) {
                    crosshairs.removeClass(activeClass);
                });
        });
}

Astro.hotspotGalleryTooltips = function() {
    $('[data-hotspot-gallery--tooltips]')
        .each(function(i, v) {
            var gallery = $(this),
                crosshairs = gallery.find('[data-hotspot-tooltip]'),
                tip = tippy('[data-hotspot-tooltip]', tippyConfig),
                hideTooltips = function() {
                    crosshairs
                        .each(function(i, v) {
                            var popper = v._tippy;
                            if (popper) {
                                tip.hide(popper);
                            }
                        });
                };

            gallery
                .on('hotspot.hide', function(e) {
                    hideTooltips();
                });

            crosshairs
                .on('crosshair.activated', function(e) {
                    $(this)[0]._tippy.show();
                })
                .on('crosshair.deactivated', function(e) {
                    $(this)[0]._tippy.hide();
                });
        });
}

Astro.hotspotGalleryProducts = function() {
    $('[data-hotspot-gallery--products]')
        .each(function(i, v) {
            var gallery = $(this),
                crosshairs = gallery.find('[data-hotspot-product]'),
                popups = gallery.find('.gallery__product'),
                hiddenClass = 'gallery__product--hidden',
                buffer = 40;

            gallery
                .on('hotspot.hide', function(e) {
                    popups.addClass(hiddenClass);
                });

            crosshairs
                .on('crosshair.activated', function() {
                    var crosshair = $(this),
                        container = crosshair.parent(),
                        crosshairWidth = crosshair.outerWidth(),
                        crosshairHeight = crosshair.outerHeight(),
                        containerWidth = container.outerWidth(),
                        containerHeight = container.outerHeight(),
                        popup = popups.filter('[data-product_id="' + crosshair.data('product_id') + '"]'),
                        popupWidth = popup.outerWidth(),
                        popupHeight = popup.outerHeight(),
                        position = crosshair.position(),
                        popup = popups.filter('[data-product_id="' + $(this).data('product_id') + '"]'),
                        x, y;


                    // blocks have the popup horizontally centred to crosshair
                    // position and the top halfway down the crosshair
                    if (container.hasClass('block__image')) {
                        x = position.left + (crosshairWidth / 2) - (popupWidth / 2);
                        y = position.top + (crosshairHeight / 2) + 20;
                    } else {
                        if (position.left + popupWidth + buffer < containerWidth) {
                            x = position.left + buffer + (crosshairWidth / 2);
                        } else if (position.left - popupWidth - buffer + (crosshairWidth / 2) >= 0) {
                            x = position.left - popupWidth - buffer + (crosshairWidth / 2);
                        }

                        if (position.top + popupHeight < containerHeight) {
                            y = position.top;
                        } else {
                            y = containerHeight - popupHeight - (buffer / 2);
                        }
                    }

                    if (x && y) {
                        popup.css({
                            left: x,
                            top: y
                        });
                    }

                    popup.removeClass(hiddenClass);
                })
                .on('crosshair.deactivated', function() {
                    var popup = popups.filter('[data-product_id="' + $(this).data('product_id') + '"]');
                    popup.addClass(hiddenClass);
                });
        });
}
