$.fn.scrollTable = function() {
    var self = $(this),
        afterResize = function() {
            self
                .each(function() {
                    var container = $(this),
                        el = container.find('.listing-item__options--desktop'),
                        table = container.find('.listing-item__table--scrollable');
                    container.toggleClass('listing-item--scrollable', table.width() > el.width());
                });
        },
        resizeTimeout;

    $(window)
        .on('resize', function() {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(function() {
                afterResize();
            }, 100);
        })
        .trigger('resize');
};
