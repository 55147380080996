var loop = new Loop();

function SlidingAccordion(options) {

    var self = this;

    this.$element = options.el;
    this.$items = options.el.querySelectorAll('.sliding-accordion__item');
    this.$toggle = options.el.querySelectorAll('.js-sliding-accordion--toggle-nav');
    this.$scrollTarget = options.scrollTarget || options.el;
    this.$scrollOffsetElements = options.scrollOffsetElements || [];

    this.init = function () {

        loop.array(this.$toggle, function (array, i) {
            array[i].onclick = function () {
                var el = document.querySelector('.sliding-accordion__item--child-active');
                if (el) {
                    el.classList.remove('sliding-accordion__item--child-active');
                }

                self.toggleClass(this.parentNode.parentNode, 'sliding-accordion__list--active');

                var el = this.parentNode.closest('.sliding-accordion__item--active');
                self.toggleClass(this.parentNode, 'sliding-accordion__item--active', {
                    negativeBefore: function () {
                        if (el) {
                            el.classList.add('sliding-accordion__item--child-active');
                        }
                    },
                });

                // Calculate offset relative to browser and scroll to top of accordion
                var top = self.calculateOffset(self.$scrollTarget, self.$scrollOffsetElements);
                $('html, body').animate({scrollTop: top}, "slow");

                return;
            }

        });
    }

    this.toggleClass = function (el, className, newCallbacks) {
        var callbacks = {
            positiveBefore: function () {
            },
            positiveAfter: function () {
            },
            negativeBefore: function () {
            },
            negativeAfter: function () {
            }
        };

        callbacks = Object.assign(callbacks, newCallbacks);

        if (el.classList.contains(className)) {
            if (typeof callbacks.positiveBefore == 'function') {
                callbacks.positiveBefore();
            }
            el.classList.remove(className);
            if (typeof callbacks.positiveAfter == 'function') {
                callbacks.positiveAfter();
            }
        } else {
            if (typeof callbacks.negativeBefore == 'function') {
                callbacks.negativeBefore();
            }
            el.classList.add(className);
            if (typeof callbacks.negativeAfter == 'function') {
                callbacks.negativeAfter();
            }
        }
    }

    this.calculateOffset = function (targetElement, additionalElements) {
        var top = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop;

        // Subtract offset of additional elements
        loop.array(additionalElements, function (array, i) {
            if (array[i]) {
                top -= array[i].offsetHeight;
            }
        });

        return top - 16;
    }
}