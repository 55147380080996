function Loop() {

    this.object = function(obj, callback) {
        var keys = Object.keys(obj);
        for (var i = 0; i < keys.length; i++) {
            (function(i) {
                callback(keys, i);
            })(i);
        }
    }

    this.array = function(array, callback, reverse) {
        if (reverse) {
            for (var i = array.length - 1; i > -1; i--) {
                (function(i) {
                    callback(array, i);
                })(i);
            }
        } else {
            for (var i = 0; i < array.length; i++) {
                (function(i) {
                    callback(array, i);
                })(i);
            }
        }
    }
}
