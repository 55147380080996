Astro.popupPages = [];

Astro.popupPage = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('.js-popup-page'), function(array, i) {
        if (array[i].hasAttribute('data-popup-init')) {
            return;
        }

        array[i].setAttribute('data-popup-page-id', i);

        var popupPage = new PopupPage(array[i]);
        popupPage.init();

        Astro.popupPages.push(popupPage);
    });
}

