Astro._scenes = [];

Astro.scenes = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('.js-scenes'), function(array, i) {
        var scenes = new Scenes({
            el: array[i]
        });
        scenes.init();

        Astro._scenes.push(scenes);
    });
};
